<template>
  <b-container fluid="md">
    <div v-if="isHappe" >
      <div>
        <vuexy-logo style="width: 100px; height: 100px" class=" mt-4" />
      </div>
      <h1 class="textHandler text-center mt-5 fs-big" id="testHandl" >شكرا بمشاركتنا أرائك</h1>
    </div>
    <div v-else >
      <div>
        <vuexy-logo style="width: 100px; height: 100px" class="mt-4" />
      </div>
      <h1 class="fs-big textHandlerServes">ما مدى رضاك عن الخدمات المقدمة في المنارة</h1>

      <div class="  d-flex justify-content-around mt-4">
        <div>
          <b-img

            class="cursor-pointer ceircal"
            max-height="500"
            src="@/assets/images/evaluations/emoje-sad.png"
            @click="
              $router.push({ name: 'enter-number', params: { state: false } })
            "
          />
        </div>
        <div class="ceircal2" style="height: 425px; background-color: #555; width: 1px"></div>
        <div class="ceircal2">
          <b-img
            class="cursor-pointer ceircal"
            max-height="500"
            src="@/assets/images/evaluations/emoje.png"
            @click="handlerHappe()"
          />
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
import { BContainer, BRow, BCol, BImg } from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import gsap from 'gsap';
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BImg,
    VuexyLogo,
    
  },
  data(){
    return{
      isHappe:false
    }
  },
    methods:{
      ...mapActions("publicPages/evaluation",["evaluationStatus"]),
      addAnimations(){
        gsap.from(".ceircal2", { transform: 'translateY(-10%) ',opacity:0.2, duration: 0.8 ,ease: 'expo' })
        gsap.from(".ceircal", { transform: 'translateY(-10%) ',opacity:0.2, duration: 0.8 ,ease: 'expo'})
      },
      addAnimationsHandler(){
        gsap.to(".ceircal2", { transform: 'translateY(-10%) ',opacity:0.2, duration: 0.8,ease: 'expo' })
        gsap.to(".ceircal", { transform: 'translateY(-10%) ',opacity:0.2, duration: 0.8,ease: 'expo'})
      },
      addAnimationsText(){
        gsap.from("#testHandl", { transform: 'translateY(-10%) ',opacity:0, duration: 1})
        gsap.fromTo('#testHandl', { y: 50, opacity: 0.4 }, { y: "0%", opacity: 1, duration: 2 })
      },
      handlerHappe(){
        this.addAnimationsHandler()
        setTimeout(() => {
          this.isHappe=true;
        }, 500);
      }
    },
  watch:{
    isHappe(val){
      if(val){
        this.evaluationStatus({status:1})
        setTimeout(() => {
          this.isHappe=false;
          this.addAnimations()
        }, 5000);
      }
    }
  },
  computed:{
    ...mapGetters("publicPages/evaluation",["loading"])
  },
  mounted(){
    this.addAnimations()

  }
};
</script>
<style lang="scss">
.fs-big {
  /* ما مدى رضاك عن الخدمات المقدمة في المنارة */
  position: relative;
  width: 100%;
  height: 75px;
  top: -80px;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 75px;
  /* identical to box height */
  text-align: center;
  color: #333;
}

</style>